import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export class WishlistMixin extends Vue {
  private get wishlist() {
    return this.$store.getters["wishlist/getWishlistByUserToVerify"];
  }

  public checkInWishlist(product_id: string, isLogged: boolean) {
    let isInWishlist = false;
    if (isLogged && this.wishlist.length > 0) {
      this.wishlist.forEach((item) => {
        if (item.fk_product == product_id) {
          isInWishlist = true;
        }
      });
    }
    return isInWishlist;
  }

  public getWishlistId(product_id: string) {
    let wishlistID = 0;
    this.wishlist.forEach((item) => {
      if (item.fk_product == product_id) {
        wishlistID = item.id;
      }
    });
    return wishlistID;
  }
}
